body {
  background: #000;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.thumbnail-aspect-ratio {
  aspect-ratio: 1.77777778;
}
.footer {
  background-color: #1b1b1b;
}
.header {
  height: 100%;
  background-color: #1b1b1b;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #242424;
}
.header-top {
  grid-template-columns: 200px 1fr 220px;
  height: 60px;
  max-width: 1323px;
}
.header-bottom {
  border-top: 1px solid #242424;
}
.header-link {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 11px;
  min-width: 60px;
  text-align: center;
  display: block;
  height: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;
}
.header-link.active {
  --tw-border-opacity: 1;
  border-color: rgba(255, 144, 0, var(--tw-border-opacity));
  border-width: 2px;
}
@media (min-width: 640px) {
  .header-link.active {
    border-width: 0px;
    border-bottom-width: 2px;
  }
}
.header-links {
  max-width: 1323px;
}
.networkbar {
  height: 22px;
}
.networkbar-link {
  color: #c6c6c6;
  text-transform: uppercase;
  padding: 5px 18.3px;
  font-size: 11px;
}
.networkbar-link:hover {
  background-color: #333333;
  color: #ffffff;
}
.comment-box:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid white;
  border-top: 5px solid white;
  border-bottom: 5px solid transparent;
  z-index: 0;
  left: -10px;
}
.w-full-plus-10px {
  width: calc(100% + 10px);
}
.chartcontainer {
  container: chartcontainer / inline-size;
  display: grid;
  grid-template-columns: fit-content(100%) 1fr fit-content(100%);
}
.chart {
  margin-bottom: 15px;
  display: none;
}
.chart-pad {
  width: 4px;
  height: 1px;
}
.media-player[data-controls][data-started] .chart {
  display: block;
}
@container chartcontainer (width > 678px) {
  .chart {
    margin-bottom: 60px;
  }
  .chart-pad {
    width: 15px;
  }
}