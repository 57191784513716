/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
b {
  font-weight: bolder;
}
button, select, input, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
button, select {
  text-transform: none;
}
button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}
button {
  background-color: transparent;
  background-image: none;
}
button, [role="button"] {
  cursor: pointer;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
h1, h2, h3, p, pre, hr {
  margin: 0;
}
h1, h2, h3 {
  font-size: inherit;
  font-weight: inherit;
}
img {
  border-style: solid;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
input::placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
pre {
  font-size: 1em;
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
textarea {
  resize: vertical;
}
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* windicss layer components */
.aspect-square {
  aspect-ratio: 1 / 1;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
/* windicss layer utilities */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 37, 37, var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.active\:bg-green-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.hover\:bg-notquiteblack:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 17, var(--tw-bg-opacity));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 144, 0, var(--tw-bg-opacity));
}
.disabled\:bg-gray-800:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.disabled\:bg-gray-500:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.disabled\:bg-opacity-50:disabled {
  --tw-bg-opacity: 0.5;
}
.enabled\:hover\:bg-opacity-90:enabled:hover {
  --tw-bg-opacity: 0.9;
}
.enabled\:active\:bg-opacity-80:enabled:active {
  --tw-bg-opacity: 0.8;
}
.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}
.active\:bg-opacity-80:active {
  --tw-bg-opacity: 0.8;
}
.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}
.bg-center {
  background-position: center;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-contain {
  background-size: contain;
}
.bg-cover {
  background-size: cover;
}
.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(255, 144, 0, var(--tw-border-opacity));
}
.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 144, 0, var(--tw-border-opacity));
}
.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}
.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.border-opacity-25 {
  --tw-border-opacity: 0.25;
}
.border-opacity-30 {
  --tw-border-opacity: 0.3;
}
.hover\:border-opacity-90:hover {
  --tw-border-opacity: 0.9;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.border-dashed {
  border-style: dashed;
}
.border-2 {
  border-width: 2px;
}
.focus\:border-1:focus {
  border-width: 1px;
}
.border-1 {
  border-width: 1px;
}
.border-t-1 {
  border-top-width: 1px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.disabled\:cursor-default:disabled {
  cursor: default;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.peer:hover ~ .peer-hover\:flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hover\:flex:hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.grid {
  display: -ms-grid;
  display: grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.justify-items-center {
  justify-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-self-end {
  -ms-grid-column-align: end;
  justify-self: end;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.font-mono {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.font-normal {
  font-weight: 400;
}
.h-0 {
  height: 0px;
}
.h-full {
  height: 100%;
}
.h-10 {
  height: 2.5rem;
}
.h-max {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.h-250px {
  height: 250px;
}
.h-100px {
  height: 100px;
}
.h-30px {
  height: 30px;
}
.h-200px {
  height: 200px;
}
.h-1\/8 {
  height: 12.5%;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-10px {
  font-size: 10px;
  line-height: 1;
}
.leading-6 {
  line-height: 1.5rem;
}
.list-inside {
  list-style-position: inside;
}
.list-disc {
  list-style-type: disc;
}
.m-auto {
  margin: auto;
}
.m-2 {
  margin: 0.5rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-1 {
  margin: 0.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mt-5px {
  margin-top: 5px;
}
.mt-20 {
  margin-top: 5rem;
}
.ml-8 {
  margin-left: 2rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-10px {
  margin-top: 10px;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-auto {
  margin-bottom: auto;
}
.mt-auto {
  margin-top: auto;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mt-\[15px\] {
  margin-top: 15px;
}
.mr-5 {
  margin-right: 1.25rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-15 {
  margin-top: 3.75rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.max-h-100px {
  max-height: 100px;
}
.max-h-\[100\%\] {
  max-height: 100%;
}
.max-w-screen {
  max-width: 100vw;
}
.max-w-1300px {
  max-width: 1300px;
}
.max-w-600px {
  max-width: 600px;
}
.max-w-800px {
  max-width: 800px;
}
.min-h-screen {
  min-height: 100vh;
}
.min-h-250px {
  min-height: 250px;
}
.min-h-80px {
  min-height: 80px;
}
.min-h-200px {
  min-height: 200px;
}
.min-w-\[250px\] {
  min-width: 250px;
}
.opacity-25 {
  opacity: 0.25;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.p-3 {
  padding: 0.75rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-10px {
  padding: 10px;
}
.p-5 {
  padding: 1.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pt-40px {
  padding-top: 40px;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pl-0 {
  padding-left: 0px;
}
.pt-\[8px\] {
  padding-top: 8px;
}
.pb-\[8px\] {
  padding-bottom: 8px;
}
.pointer-events-none {
  pointer-events: none;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.left-0 {
  left: 0px;
}
.top-0 {
  top: 0px;
}
.right-0 {
  right: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.resize-none {
  resize: none;
}
.table-auto {
  table-layout: auto;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgba(255, 144, 0, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 216, var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.active\:text-white:active {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.disabled\:text-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.invisible {
  visibility: hidden;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.break-all {
  word-break: break-all;
}
.w-full {
  width: 100%;
}
.w-0 {
  width: 0px;
}
.w-10 {
  width: 2.5rem;
}
.w-screen {
  width: 100vw;
}
.w-1\/4 {
  width: 25%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-1\/2 {
  width: 50%;
}
.w-max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.w-4\/7 {
  width: 57.142857%;
}
.w-3\/7 {
  width: 42.857143%;
}
.w-250px {
  width: 250px;
}
.w-30px {
  width: 30px;
}
.z-10 {
  z-index: 10;
}
.z-50 {
  z-index: 50;
}
.gap-10px {
  grid-gap: 10px;
  gap: 10px;
}
.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.auto-cols-auto {
  grid-auto-columns: auto;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.duration-500 {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
@-webkit-keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-md {
  --tw-blur: blur(12px);
}
.hover\:blur-0:hover {
  --tw-blur: blur(0);
}
@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }
  .sm\:grid {
    display: -ms-grid;
    display: grid;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:text-left {
    text-align: left;
  }
  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }
}
@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
  .md\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .md\:p-10 {
    padding: 2.5rem;
  }
  .md\:pl-10 {
    padding-left: 2.5rem;
  }
  .md\:pr-10 {
    padding-right: 2.5rem;
  }
  .md\:w-2\/3 {
    width: 66.666667%;
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .md\:col-span-7 {
    -ms-grid-column-span: span 7 / span 7;
    grid-column: span 7 / span 7;
  }
  .md\:col-span-3 {
    -ms-grid-column-span: span 3 / span 3;
    grid-column: span 3 / span 3;
  }
}
@media (min-width: 1024px) {
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}